import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import FraudReportAnalysis from './pages/FraudReportAnalysis';
import Sidebar from './components/Sidebar';
import PrivateRoute from './components/PrivateRoute';
import WebsiteAnalysis from './pages/WebsiteAnalysis';
import IoscoAlertCompanySearch from './pages/IoscoAlertCompanySearch';
import CompleteAnalysis from './pages/CompleteAnalysis';
import ConsobListCompanySearch from './pages/ConsobListCompanySearch';
import GoogleAlertSearch from './pages/GoogleAlertSeatch';

const App: React.FC = () => {
  const { keycloak, initialized } = useKeycloak();

  if (!initialized) {
    console.log("CLient non inizializzato")
    return <div>Loading...</div>;
  }

  if (!keycloak.authenticated) {
    keycloak.login();
    return null;
  }


  return (
    <Router>
      <div className="flex">
        <Sidebar />
        <div className={`p-4 ml-64 w-full`}>
          <Routes>
            <Route path="/" element={<PrivateRoute component={FraudReportAnalysis} />} />
            <Route path="/website-analysis" element={<PrivateRoute component={WebsiteAnalysis} />} />
            <Route path="/complete-analysis" element={<PrivateRoute component={CompleteAnalysis} />} />
            <Route path="/search-iosco-alert" element={<PrivateRoute component={IoscoAlertCompanySearch} />} />
            <Route path="/search-consob-lists" element={<PrivateRoute component={ConsobListCompanySearch} />} />
            <Route path="/search-google" element={<PrivateRoute component={GoogleAlertSearch} />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
};

export default App;
