import axios from '../api/axios';
import { FraudReport } from '../models/FraudReport';

const analyzeFraudReport = async (blockedSitesFiles: File[], multipleFiles: File[]): Promise<FraudReport> => {
    const formData = new FormData();
    if (blockedSitesFiles.length > 0) {
        formData.append('url_file', blockedSitesFiles[0]);
    }
    multipleFiles.forEach((file) => {
        formData.append('uploaded_files', file, file.name);
    });

    try {
        const response = await axios.post<FraudReport>('/analyze/fraud-report', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return response.data;
    } catch (error) {
        throw new Error('Error uploading files');
    }
};

export { analyzeFraudReport };
