import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { HiOutlineLogout, HiOutlineDocumentText, HiOutlineGlobeAlt, HiOutlineSearch, HiOutlineExclamationCircle, HiOutlineDocumentDuplicate, HiOutlineSearchCircle } from 'react-icons/hi';

import logo from '../assets/Logo CONSOB.png'; // Assicurati di avere un logo nella cartella src/assets
import keycloak from '../keycloak';

const Sidebar: React.FC = () => {

    const [userFullName, setUserFullName] = useState<string>('Utente');
    const [userInitials, setUserInitials] = useState<string>('Utente');

    useEffect(() => {
        if (keycloak.authenticated) {
            // Controlla che l'utente sia autenticato e recupera il nome dal token
            const fullName = keycloak.tokenParsed?.name || 'Utente'; // Recupera il nome dal token JWT
            setUserFullName(fullName);
        }
    }, []);

    useEffect(() => {
        if (userFullName) {
            const initials = userFullName.split(" ").map(name => name[0]).join("");
            setUserInitials(initials);
        }
    }, [userFullName]);


    const handleLogout = () => {
        keycloak.logout({
            redirectUri: window.location.origin, // Optional: Redirect to a specific URL after logout
        });
    };

    const activeStyle = `border border-gray-30 flex items-center font-medium text-sm px-3 py-2 h-10 bg-gradient-to-r from-primary-color to-blue-600 text-white shadow-lg transition-all duration-300 ease-in-out m-2 rounded-lg transform hover:scale-105 hover:shadow-xl`;

    const inavtiveStyle = `flex items-center font-normal text-sm px-3 py-2 h-10 overflow-hidden text-secondary-color text-ellipsis whitespace-nowrap rounded-lg hover:text-primary-color hover:bg-primary-color hover:bg-opacity-10 hover:shadow-md transition-all duration-300 ease-in-out m-2 transform hover:scale-105`;

    return (
        <div className="w-64 h-full shadow-md border-r-2 border-gray-200 bg-gray-50 fixed flex flex-col justify-between py-2">
            <div>
                <div className="flex items-center justify-center pt-8">
                    <img src={logo} alt="Logo" className="h-20" /> {/* Set the size using Tailwind CSS classes */}
                </div>
                <div className="flex items-center py-2 px-2 gap-x-3 mt-6 border rounded-md mx-2 bg-white">
                    <div className="bg-primary-color text-white rounded-md w-9 h-9 flex items-center justify-center text-md font-bold">
                        {userInitials}
                    </div>
                    <div>
                        <h3 className="text-sm font-semibold text-gray-700">{userFullName}</h3>
                    </div>
                </div>
                <div className='py-0'>
                    <ul className="relative">
                        <li className="relative">
                            <NavLink
                                to="/"
                                className={({ isActive }) =>
                                    isActive
                                        ? activeStyle
                                        : inavtiveStyle
                                }
                            >
                                <HiOutlineDocumentText className="mr-3" />
                                Analisi Esposti
                            </NavLink>
                        </li>
                        <li className="relative">
                            <NavLink
                                to="/website-analysis"
                                className={({ isActive }) =>
                                    isActive
                                        ? activeStyle
                                        : inavtiveStyle
                                }
                            >
                                <HiOutlineGlobeAlt className="mr-3" />
                                Analisi Sito Internet
                            </NavLink>
                        </li>

                        <li className="relative">
                            <NavLink
                                to="/complete-analysis"
                                className={({ isActive }) =>
                                    isActive
                                        ? activeStyle
                                        : inavtiveStyle
                                }
                            >
                                <HiOutlineSearch className="mr-3" />
                                Analisi Completa
                            </NavLink>
                        </li>

                        <li className="relative">
                            <NavLink
                                to="/search-iosco-alert"
                                className={({ isActive }) =>
                                    isActive
                                        ? activeStyle
                                        : inavtiveStyle
                                }
                            >
                                <HiOutlineExclamationCircle className="mr-3" />
                                Ricerca Alert IOSCO
                            </NavLink>
                        </li>
                        <li className="relative">
                            <NavLink
                                to="/search-consob-lists"
                                className={({ isActive }) =>
                                    isActive
                                        ? activeStyle
                                        : inavtiveStyle
                                }
                            >
                                <HiOutlineDocumentDuplicate className="mr-3" />
                                Ricerca Elenchi CONSOB
                            </NavLink>
                        </li>
                        <li className="relative">
                            <NavLink
                                to="/search-google"
                                className={({ isActive }) =>
                                    isActive
                                        ? activeStyle
                                        : inavtiveStyle
                                }
                            >
                                <HiOutlineSearchCircle className="mr-3" />
                                Ricerca sul Web
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </div>
            <div>
                <button
                    onClick={handleLogout}
                    className={inavtiveStyle}
                >
                    <HiOutlineLogout className="mr-3" />
                    Esci
                </button>
            </div>
        </div>
    );
};

export default Sidebar;
