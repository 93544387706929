import React, { useState } from 'react';
import { WebsiteReport } from '../models/WebsiteReport';
import { downloadWebsiteReportEvaluation } from '../api/evaluation';


interface DownloadWebsiteReportEvaluationButtonProps {
    websiteReport: WebsiteReport;
}

const DownloadWebsiteReportEvaluationButton: React.FC<DownloadWebsiteReportEvaluationButtonProps> = ({ websiteReport }) => {
    const [isLoading, setIsLoading] = useState(false);

    const handleDownloadWebsiteReportEvaluationButton = async () => {
        setIsLoading(true);
        try {
            const url = await downloadWebsiteReportEvaluation(websiteReport)
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `report_sito_${websiteReport.service_name?.replace(/ /g, "_") ?? ""}.txt`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error('Error downloading the fraud report evaluation', error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <button
            onClick={handleDownloadWebsiteReportEvaluationButton}
            className="bg-primary-color text-white px-4 py-2 my-4 rounded-md"
            disabled={isLoading}
        >
            {isLoading ? 'Elaboro ...' : 'Scarica Report'}
        </button>
    );
};

export default DownloadWebsiteReportEvaluationButton;
