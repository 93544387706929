// src/components/WebsiteAnalysis.tsx
import React, { useState } from 'react';
import axios from 'axios';
import IOSCOAlertTable from '../components/IOSCOAlertTable';
import { IOSCOAlert } from '../models/IOSCOAlert';
import { fetchIOSCOAlerts } from '../api/searchApi';


const IoscoAlertCompanySearch: React.FC = () => {
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [alerts, setAlerts] = useState<IOSCOAlert[] | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const handleSearch = async (event: React.FormEvent) => {
        event.preventDefault();
        setLoading(true);
        setError(null);

        try {
            const results = await fetchIOSCOAlerts(searchTerm);
            setAlerts(results);
            console.log(alerts);
        } catch (error: any) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="container mx-auto p-4">
            <h1 className="text-xl font-bold mb-4 text-primary-color">Cerca Alert IOSCO</h1>
            <div className='max-w-4xl my-4'>
                <p>
                    Cliccando su "Cerca", verrà effettuata una ricerca sulla piattaforma di alert IOSCO.<br />
                    Verranno estratte in tempo reale le aziende con un nome simile a quello inserito, utilizzando i dati disponibili sul portale IOSCO.</p>
            </div>
            <form onSubmit={handleSearch} className="mb-4 grid grid-cols-2 sm:grid-cols-auto-fit gap-4 items-center max-w-4xl">
                <input
                    type="text"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="border p-2 flex-grow"
                    placeholder="Inserisci il nome dell'azienda..."
                />
                <div>
                    <button
                        type="submit"
                        className={`bg-primary-color text-white px-4 py-2 rounded flex items-center justify-center ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
                        disabled={loading}
                    >
                        {loading ? (
                            <>
                                <span>Ricerca in corso</span>
                                <svg className="animate-spin h-5 w-5 ml-3 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
                                </svg>
                            </>
                        ) : 'Cerca'}
                    </button>
                </div>
            </form>
            {error && <p className="text-red-500">{error}</p>}
            {alerts && <IOSCOAlertTable alerts={alerts} />}
        </div>
    );
};

export default IoscoAlertCompanySearch;
