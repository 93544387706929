import React, { useState } from 'react';
import { downloadScreenshots } from '../api/downloadScreenshots';


interface DownloadScreenshotsButtonProps {
    urls: string[];
}

const DownloadScreenshotsButton: React.FC<DownloadScreenshotsButtonProps> = ({ urls }) => {
    const [isLoading, setIsLoading] = useState(false);

    const handleDownloadScreenshots = async () => {
        setIsLoading(true);
        try {
            const url = await downloadScreenshots(urls)
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'screenshots.zip');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error('Error downloading the screenshots', error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <button
            onClick={handleDownloadScreenshots}
            className="bg-primary-color text-white px-4 py-2 my-4 rounded-md"
            disabled={isLoading}
        >
            {isLoading ? 'Elaboro ...' : 'Scarica Screenshot'}
        </button>
    );
};

export default DownloadScreenshotsButton;
