import keycloak from '../keycloak';

const getJwtToken = (): string | null => {
    if (keycloak.authenticated) {
        return keycloak.token!;
    } else {
        console.error('User is not authenticated.');
        return null;
    }
};

export default getJwtToken;
