import React, { useState } from 'react';
import axios from 'axios';
import { FaTrash, FaFileAlt, FaChevronLeft } from 'react-icons/fa';
import FileUploader from '../components/FileUploader';
import { FraudReport } from '../models/FraudReport';
import FraudReportWidget from '../components/FraudReportWidget';
import { analyzeFraudReport } from '../api/fraudReportApi';
import DownloadFraudReportEvaluationButton from '../components/downloadFraudReportEvaluationButton';

const FraudReportAnalysis: React.FC = () => {
    const [blockedSitesFiles, setBlockedSitesFiles] = useState<File[]>([]);
    const [multipleFiles, setMultipleFiles] = useState<File[]>([]);
    const [analysisResult, setAnalysisResult] = useState<FraudReport | null>(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isAccordionOpen, setIsAccordionOpen] = useState(false);

    const handleBlockedSitesUpload = (acceptedFiles: File[]) => {
        setBlockedSitesFiles([...blockedSitesFiles, ...acceptedFiles]);
    };

    const handleMultipleFilesUpload = (acceptedFiles: File[]) => {
        setMultipleFiles([...multipleFiles, ...acceptedFiles]);
    };

    const handleRemoveBlockedSiteFile = (fileToRemove: File) => {
        setBlockedSitesFiles(blockedSitesFiles.filter(file => file !== fileToRemove));
    };

    const handleRemoveMultipleFile = (fileToRemove: File) => {
        setMultipleFiles(multipleFiles.filter(file => file !== fileToRemove));
    };

    const toggleAccordion = () => {
        setIsAccordionOpen(!isAccordionOpen);
    };

    const handleSubmit = async () => {
        if (multipleFiles.length === 0) {
            alert('Devi caricare almeno un file esposto.');
            return;
        }

        setIsSubmitting(true);
        setAnalysisResult(null);
        try {
            const result = await analyzeFraudReport(blockedSitesFiles, multipleFiles);
            setAnalysisResult(result);
        } catch (error) {
            console.error('Error uploading files:', error);
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div className="container mx-auto p-4">
            <h1 className="text-xl font-bold mb-4 text-primary-color">Analisi Esposti</h1>

            {/* Primo contenitore per il FileUploader */}
            <div className="border border-gray-300 rounded-lg shadow-sm my-6 w-full max-w-4xl">
                <div
                    className="flex justify-between items-center px-4 py-3 cursor-pointer hover:bg-gray-50"
                    onClick={toggleAccordion}
                >
                    <h2 className="font-medium">Carica il file contenente i siti oscurati</h2>
                    {/* Icona che ruota */}
                    <FaChevronLeft
                        className={`transform transition-transform duration-300 ${isAccordionOpen ? '-rotate-90' : 'rotate-0'}`} // Classe di rotazione
                    />
                </div>

                {/* Aggiungi la transizione per l'apertura e chiusura dell'accordion */}
                <div
                    className={`overflow-hidden transition-all duration-500 ease-in-out ${isAccordionOpen ? 'max-h-96' : 'max-h-0'
                        }`}
                >
                    <div className="p-6">
                        <FileUploader
                            onDrop={handleBlockedSitesUpload}
                            label="Trascina e rilascia il file qui, o fai clic per selezionare i file"
                            maxFiles={1}
                            acceptedFormats={['txt']}
                        />
                        <div>
                            {blockedSitesFiles.map((file, index) => (
                                <div
                                    key={index}
                                    className="flex justify-between items-center py-2 px-6 w-full border rounded-md mb-2 shadow-sm"
                                >
                                    <div className="flex items-center">
                                        <FaFileAlt className="text-gray-700 mr-2 text-2xl" />
                                        <div className="flex flex-col px-4 text-left">
                                            <span className="text-gray-700 font-medium">{file.name}</span>
                                            <span className="text-gray-500 text-sm">
                                                ({(file.size / 1024).toFixed(2)} KB)
                                            </span>
                                        </div>
                                    </div>
                                    <button
                                        onClick={() => handleRemoveBlockedSiteFile(file)}
                                        className="text-red-700 hover:text-red-900"
                                    >
                                        <FaTrash />
                                    </button>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>

            {/* Secondo contenitore per il FileUploader */}
            <div className={`border border-gray-300  rounded-lg shadow-sm my-6 w-full max-w-4xl`}>
                <div className={`flex justify-between items-center bg-gray-50 px-4 py-3 cursor-pointer border-b`}>
                    <h2 className="font-medium">Carica i documeti da processare</h2>
                </div>
                <div className='p-6'>
                    <FileUploader onDrop={handleMultipleFilesUpload} label="Trascina e rilascia più file qui, o fai clic per selezionare i file" />
                    <div>
                        {multipleFiles.map((file, index) => (
                            <div key={index} className="flex justify-between items-center py-2 px-6 w-full border rounded-md mb-2 shadow-sm">
                                <div className="flex items-center">
                                    <FaFileAlt className="text-gray-700 mr-2 text-2xl" />
                                    <div className='flex flex-col px-4 text-left'>
                                        <span className="text-gray-700 font-medium">{file.name}</span>
                                        <span className="text-gray-500 text-sm">({(file.size / 1024).toFixed(2)} KB)</span>
                                    </div>
                                </div>
                                <button onClick={() => handleRemoveMultipleFile(file)} className="text-red-700 hover:text-red-900">
                                    <FaTrash />
                                </button>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className='max-w-4xl'>
                <h2 className='font-semibold text-primary-color mb-2'>Istruzioni per l'Uso</h2>
                <ol className='list-decimal ml-5'>
                    <li>Nella sezione <span className='font-semibold'>"Carica il file contenente i siti oscurati"</span>, carica il file che contiene l'elenco dei domini oscurati. Il file deve essere in formato .txt e ogni riga deve rappresentare un dominio oscurato. <span className='font-semibold'>(facoltativo)</span></li>
                    <li>Nella sezione <span className='font-semibold'>"Carica i documenti da processare"</span>, carica i file relativi all'esposto. I file possono essere in formato .pdf, .jpg, .jpeg, o .png. <span className='font-semibold'>(obbligatorio)</span></li>
                    <li>Seleziona <span className='font-semibold'>"Procedi all'analisi"</span> per avviare il processo di analisi dei documenti. <span className='font-semibold'>(obbligatorio)</span></li>
                    <li>Modifica l'esito dell'analisi se ritieni che i risultati non siano in linea con la valutazione dell'operatore. <span className='font-semibold'>(facoltativo)</span></li>
                    <li>Indica se l'output fornito è coerente con il contenuto dei documenti e aggiungi eventuali note nel campo dedicato. <span className='font-semibold'>(facoltativo)</span></li>
                    <li>Scarica il report con l'esito dell'analisi. <span className='font-semibold'>(facoltativo)</span></li>
                </ol>
            </div>
            <div className="mt-6">
                <button
                    onClick={handleSubmit}
                    className={`bg-primary-color text-white px-4 py-2 rounded-md flex items-center justify-center ${isSubmitting ? 'opacity-50 cursor-not-allowed' : ''}`}
                    disabled={isSubmitting}
                >
                    {isSubmitting ? (
                        <>
                            <span>Analisi in corso</span>
                            <svg className="animate-spin h-5 w-5 ml-3 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
                            </svg>
                        </>
                    ) : 'Procedi all\'analisi'}
                </button>
            </div>


            {analysisResult && (
                <div className='max-w-4xl'>
                    <FraudReportWidget report={analysisResult} setReport={setAnalysisResult} testMode={true} />
                </div>

            )}
        </div>
    );
};

export default FraudReportAnalysis;
