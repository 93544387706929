export function formatDateToItalian(date: Date): string {
    const mesiAnno = [
        'gennaio', 'febbraio', 'marzo', 'aprile', 'maggio', 'giugno',
        'luglio', 'agosto', 'settembre', 'ottobre', 'novembre', 'dicembre'
    ];

    const giorno = date.getDate();
    const mese = mesiAnno[date.getMonth()];
    const anno = date.getFullYear();

    // Aggiungi "°" solo al giorno se il giorno è 1
    const giornoConSuffisso = giorno === 1 ? `${giorno}°` : `${giorno}`;

    return `${giornoConSuffisso} ${mese} ${anno}`;
}
