import axios from 'axios';
import getJwtToken from '../utility/auth';

const instance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL
});

instance.interceptors.request.use(
    (config) => {
        // Retrieve the JWT token from localStorage (or wherever you're storing it)
        const jwtToken = getJwtToken();

        if (jwtToken) {
            // Attach the token to the Authorization header
            config.headers.Authorization = `Bearer ${jwtToken}`;
        }

        console.log(config.headers)

        return config;
    },
    (error) => {
        // Handle the error
        console.log(error);
        return Promise.reject(error);
    }
);

export default instance;