import axios from './axios';

interface ReplacementDict {
    [key: string]: string[];
}

const generatedReport = async (replacements: ReplacementDict): Promise<string> => {
    try {
        const response = await axios.post(
            '/generate/report',
            replacements, // Senza le parentesi graffe
            { responseType: 'blob' } // Importante per gestire la risposta binaria
        );
        // Crea un link per scaricare il file
        return window.URL.createObjectURL(new Blob([response.data]));
    } catch (error) {
        throw new Error('Error downloading the report');
    }
};

const generateDelibera = async (replacements: ReplacementDict): Promise<string> => {
    try {
        const response = await axios.post(
            '/generate/delibera',
            replacements, // Senza le parentesi graffe
            { responseType: 'blob' } // Importante per gestire la risposta binaria
        );
        // Crea un link per scaricare il file
        return window.URL.createObjectURL(new Blob([response.data]));
    } catch (error) {
        throw new Error('Error downloading the report');
    }
};

export { generatedReport, generateDelibera };
