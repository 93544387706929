import React from 'react';

interface SearchBarProps {
    searchTerm: string;
    onSearchTermChange: (term: string) => void;
    onSearch: (event: React.FormEvent) => void;
    loading: boolean;
}

const SearchBar: React.FC<SearchBarProps> = ({ searchTerm, onSearchTermChange, onSearch, loading }) => {
    return (
        <form onSubmit={onSearch} className="mb-4 grid grid-cols-2 sm:grid-cols-auto-fit gap-4 items-center">
            <input
                type="text"
                value={searchTerm}
                onChange={(e) => onSearchTermChange(e.target.value)}
                className="border p-2 flex-grow"
                placeholder="Inserisci il termine di ricerca..."
            />
            <div>
                <button
                    type="submit"
                    className={`bg-primary-color text-white px-4 py-2 rounded flex items-center justify-center ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
                    disabled={loading}
                >
                    {loading ? (
                        <>
                            <span>Analisi in corso</span>
                            <svg className="animate-spin h-5 w-5 ml-3 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
                            </svg>
                        </>
                    ) : 'Avvia Analisi'}
                </button>
            </div>
        </form>
    );
};

export default SearchBar;
