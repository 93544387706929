import Keycloak from "keycloak-js";

const keycloak = new Keycloak({
    url: "https://lemur-15.cloud-iam.com/auth",
    realm: "via-keycloak",
    clientId: "vfaesposti",
});

// Aggiungi i listener per il logging
keycloak.onReady = () => console.log('Keycloak is ready');
keycloak.onAuthSuccess = () => console.log('Keycloak authentication successful');
keycloak.onAuthError = (error) => console.error('Keycloak authentication error:', error);
keycloak.onAuthRefreshSuccess = () => console.log('Keycloak token refresh success');
keycloak.onAuthRefreshError = () => console.error('Keycloak token refresh error');
keycloak.onTokenExpired = () => console.warn('Keycloak token expired');

export default keycloak;