// src/components/WebsiteReportSearch.tsx
import React, { useState } from 'react';
import { WebsiteReport } from '../models/WebsiteReport';
import WebsiteReportWidget from '../components/WebsitreReportWidget'; // Correggi typo nel nome
import { fetchWebsiteReport } from '../api/websiteReportApi';
import SearchBar from '../components/SearchBar'; // Importa il nuovo componente
import { isValidURL } from '../utils/urlChecker';

const WebsiteAnalysis: React.FC = () => {
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [report, setReport] = useState<WebsiteReport | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const handleSearch = async (event: React.FormEvent) => {
        event.preventDefault();
        setLoading(true);
        setError(null);
        setReport(null)
        if (isValidURL(searchTerm)) {
            try {
                const result = await fetchWebsiteReport(searchTerm);
                setReport(result);
            } catch (error: any) {
                console.log('- - - - - - - - - -')
                console.log(error.message)
                console.log('- - - - - - - - - -')
                if (error.message === "WEBSITE_NOT_ACCESSIBLE") {
                    console.log(`codice errore: ${error.code}`)
                    setError(`Il sito web ${searchTerm} non è attualmente raggiungibile. Verifica che l'indirizzo sia corretto o prova di nuovo più tardi.`);
                } else if (error.message === "CONTENT_PROTECTED") {
                    setError(`Il sito web ${searchTerm} presenta dei sistemi di protezione avanzati. Verifica che l'indirizzo sia corretto o prova di nuovo più tardi.`);
                }

                else {
                    console.log(`Impossibile procedere con l'analisi del sito ${searchTerm}, erorre ${error.code}: ${error.message}.`)
                    setError(`Impossibile procedere con l'analisi del sito ${searchTerm}. Riprova più tardi.`);
                }
            } finally {
                setLoading(false);
            }
        } else {
            setError("Il formato dell'URL inserito non è corretto. Per favore, verifica e riprova.");
            setLoading(false);
        }

    };

    return (
        <div className='container mx-auto p-4'>
            <div className="max-w-4xl">
                <h1 className="text-xl font-bold mb-4 text-primary-color">Analisi Sito Internet</h1>
                <SearchBar
                    searchTerm={searchTerm}
                    onSearchTermChange={setSearchTerm}
                    onSearch={handleSearch}
                    loading={loading}
                />
                {error && <p className="text-red-700 max-w-2xl">{error}</p>}
                {report && (
                    <WebsiteReportWidget report={report} setReport={setReport} testMode={true} />
                )}
            </div>
        </div>

    );
};

export default WebsiteAnalysis;
