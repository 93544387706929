import React from 'react';
import { ConosbRegistration } from '../models/ConsobRegistration';

interface ConsobListtTableProps {
    registrations: ConosbRegistration[];
}

const ConsobListTable: React.FC<ConsobListtTableProps> = ({ registrations }) => {
    return (
        <>
            <table className="min-w-full bg-white border border-gray-300">
                <thead>
                    <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
                        <th className="py-3 px-6 text-left">Impresa</th>
                        <th className="py-3 px-6 text-left">Sede legale</th>
                        <th className="py-3 px-6 text-left">Città</th>
                        <th className="py-3 px-6 text-left">Stato</th>
                        <th className="py-3 px-6 text-left">Numero di iscrizione</th>
                        <th className="py-3 px-6 text-left">Tipologia</th>
                    </tr>
                </thead>
                <tbody className="text-gray-600 text-sm font-light">
                    {registrations.map((registration, index) => (
                        <tr key={index} className="border-b border-gray-200 hover:bg-gray-100">
                            <td className="py-3 px-6 text-left whitespace-nowrap">{registration.investment_company}</td>
                            <td className="py-3 px-6 text-left">{registration.registered_office}</td>
                            <td className="py-3 px-6 text-left">{registration.city}</td>
                            <td className="py-3 px-6 text-left">{registration.country}</td>
                            <td className="py-3 px-6 text-left">{registration.registration_number}</td>
                            <td className="py-3 px-6 text-left">{registration.type}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    );
};

export default ConsobListTable;
