import React from 'react';
import { useDropzone, Accept } from 'react-dropzone';
import { FaFileUpload } from 'react-icons/fa';

interface FileUploaderProps {
    onDrop: (acceptedFiles: File[]) => void;
    label: string;
    acceptedFormats?: string[]; // Formati accettati opzionali
    maxFiles?: number; // Numero massimo di file da caricare opzionale
}

const FileUploader: React.FC<FileUploaderProps> = ({ onDrop, label, acceptedFormats, maxFiles }) => {
    // Creiamo un oggetto Accept solo se ci sono formati accettati specificati
    const accept: Accept | undefined = acceptedFormats
        ? acceptedFormats.reduce((acc, format) => {
            if (format === 'pdf') {
                acc['application/pdf'] = ['.pdf'];
            } else if (format === 'txt') {
                acc['text/plain'] = ['.txt'];
            } else if (format === 'jpg' || format === 'jpeg') {
                acc['image/jpeg'] = acc['image/jpeg'] ? [...acc['image/jpeg'], `.${format}`] : [`.${format}`];
            } else if (format === 'png') {
                acc['image/png'] = ['.png'];
            } else if (format === 'gif') {
                acc['image/gif'] = ['.gif'];
            } // Puoi aggiungere altri formati di file se necessario
            return acc;
        }, {} as Accept)
        : undefined;

    const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
        onDrop,
        noClick: true, // Disabilita il click diretto sull'area di drop
        accept, // Usare il tipo corretto per accept
        maxFiles: maxFiles || undefined, // Limite di file da caricare
    });

    return (
        <div
            {...getRootProps()}
            className="border-dashed border-2 border-gray-300 bg-gray-500 bg-opacity-10  p-8 text-center my-4 rounded-lg w-full flex flex-col justify-center items-center"
            style={{ maxWidth: '100%' }}
        >
            <input {...getInputProps()} />
            <div className="flex flex-col items-center justify-center">
                <FaFileUpload size={36} className=" text-gray-600 mb-4" />
                {isDragActive ? (
                    <p className="text-gray-500 font-semibold">Rilascia qui i file ...</p>
                ) : (
                    <p className="text-gray-500 font-semibold">
                        Trascina qui i file da caricare.
                    </p>
                )}

                <div className="flex items-center w-full my-4">
                    <div className="flex-grow border-t border-gray-300"></div>
                    <span className="px-2 text-gray-500 text-sm">oppure</span>
                    <div className="flex-grow border-t border-gray-300"></div>
                </div>

                <button
                    type="button"
                    onClick={open}
                    className="px-4 bg-gray-500 hover:bg-gray-700 text-white rounded-md shadow h-8 text-sm"
                >
                    Carica File
                </button>
            </div>
            {acceptedFormats && (
                <p className="mt-2 text-gray-500 text-sm">
                    Formati accettati: {acceptedFormats.join(', ')}
                </p>
            )}
            {maxFiles && (
                <p className="mt-1 text-gray-500 text-sm">
                    Puoi caricare fino a {maxFiles} file.
                </p>
            )}
        </div>
    );
};

export default FileUploader;
