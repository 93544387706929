import React, { useState } from 'react';
import { FraudReport } from '../models/FraudReport';
import { downloadFraudReportEvaluation } from '../api/evaluation';


interface DownloadFraudReportEvaluationButtonProps {
    fraudReport: FraudReport;
}

const DownloadFraudReportEvaluationButton: React.FC<DownloadFraudReportEvaluationButtonProps> = ({ fraudReport }) => {
    const [isLoading, setIsLoading] = useState(false);

    const handleDownloadFraudReportEvaluationButton = async () => {
        setIsLoading(true);
        try {
            const url = await downloadFraudReportEvaluation(fraudReport)
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `report_esposto_${fraudReport.company_names.length > 0 ? fraudReport.company_names[0].replace(/ /g, "_") : ""}.txt`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error('Error downloading the fraud report evaluation', error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <button
            onClick={handleDownloadFraudReportEvaluationButton}
            className="bg-primary-color text-white px-4 py-2  rounded-md "
            disabled={isLoading}
        >
            {isLoading ? 'Elaboro ...' : 'Scarica l\'esito dell\'analisi'}
        </button>
    );
};

export default DownloadFraudReportEvaluationButton;
