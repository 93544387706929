import axios from '../api/axios';
import { Accusation } from '../models/Accusation';
import { ConosbRegistration } from '../models/ConsobRegistration';
import { IOSCOAlert } from "../models/IOSCOAlert";


const fetchIOSCOAlerts = async (companyName: string): Promise<IOSCOAlert[]> => {
    try {
        const response = await axios.get<{ company_name: string; results: IOSCOAlert[] }>(
            `/search/iosco-alerts?company_name=${companyName}`
        );
        return response.data.results;
    } catch (error) {
        console.log(error)
        throw new Error('Impossibile procedere con la richiesta, riprovare.');
    }
};

const fetchConsobRegistrations = async (companyName: string): Promise<ConosbRegistration[]> => {
    try {
        const response = await axios.get<{ company_name: string; results: ConosbRegistration[] }>(
            `/search/consob-registrations?company_name=${companyName}`
        );
        return response.data.results;
    } catch (error) {
        console.log(error)
        throw new Error('Impossibile procedere con la richiesta, riprovare.');
    }
};

const fetchAccusations = async (companyName: string): Promise<Accusation[]> => {
    try {
        const response = await axios.get<{ company_name: string; results: Accusation[] }>(
            `/search/google?company_name=${companyName}`, { timeout: 180000 }
        );
        return response.data.results;
    } catch (error) {
        console.log(error)
        throw new Error('Impossibile procedere con la richiesta, riprovare');
    }
};

export { fetchIOSCOAlerts, fetchConsobRegistrations, fetchAccusations };