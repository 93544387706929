import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import keycloak from './keycloak';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const keycloakInitOptions = {
  onLoad: 'check-sso', // Tries to check if the user is already logged in without forcing a login
  checkLoginIframe: false,  // Disable iframe check
};


root.render(
  <ReactKeycloakProvider authClient={keycloak} initOptions={keycloakInitOptions}>
    <App />
  </ReactKeycloakProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
