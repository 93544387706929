import React from 'react';
import { FraudReport } from '../models/FraudReport';
import DownloadFraudReportEvaluationButton from './downloadFraudReportEvaluationButton';

interface FraudReportWidgetProps {
    report: FraudReport;
    setReport: (report: FraudReport | null) => void;
    testMode?: boolean;
}

const FraudReportWidget: React.FC<FraudReportWidgetProps> = ({ report, setReport, testMode = false }) => {

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        const newValue = value === 'true' ? true : value === 'false' ? false : value;

        setReport({
            ...report,
            [name]: newValue,
        });
    };

    const handleListFieldChange = (e: React.ChangeEvent<HTMLInputElement>, fieldName: keyof FraudReport) => {
        const { value } = e.target;
        const newValue = value.split(',').map(item => item.trim());

        setReport({
            ...report,
            [fieldName]: newValue,
        });
    };

    let bannerMessage = "";
    let resultTextColor = "";

    if (report.consob_interest && report.active_contact) {
        bannerMessage = "Sono presenti attività di competenza e contatto attivo";
        resultTextColor = "text-red-700";
    } else if (report.consob_interest && !report.active_contact) {
        bannerMessage = "Esito: Presenza di riferimenti ad attività di competenza ma contatto non attivo";
        resultTextColor = "text-orange-400";
    } else {
        bannerMessage = "Esito: Non sono presenti riferimenti ad Attività di Interesse";
        resultTextColor = "text-gray-500";
    }

    return (
        <div className="container mx-auto border rounded-lg p-6 shadow-md my-6">
            <h1 className="text-lg font-medium">Esito Analisi Esposto</h1>
            <div className="border-t border-gray-300 mt-1 mb-6"></div>
            <div className="grid grid-cols-1 gap-4">
                <div>
                    <label className="block font-mediun mb-1 text-sm">Soggetti Segnalati</label>
                    <input type="text" name="company_names" value={report.company_names.join(', ')} onChange={(e) => handleListFieldChange(e, 'company_names')} className="border p-2 w-full" />
                </div>
                <div>
                    <label className="block font-mediun mb-1 text-sm">Siti Internet</label>
                    <input type="text" name="websites" value={report.websites.join(', ')} onChange={(e) => handleListFieldChange(e, 'websites')} className="border p-2 w-full" />
                </div>
                <div>
                    <label className="block font-mediun mb-1 text-sm">E-mail</label>
                    <input type="text" name="emails" value={report.emails.join(', ')} onChange={(e) => handleListFieldChange(e, 'emails')} className="border p-2 w-full" />
                </div>
                <div>
                    <label className="block font-mediun mb-1 text-sm">Nomi Rilevati</label>
                    <input type="text" name="contact_names" value={report.contact_names.join(', ')} onChange={(e) => handleListFieldChange(e, 'contact_names')} className="border p-2 w-full" />
                </div>
                <div>
                    <label className="block font-mediun mb-1 text-sm">Contatto Attivo</label>
                    <select name="active_contact" value={report.active_contact.toString()} onChange={handleChange} className="border p-2 w-full">
                        <option value="true">Sì</option>
                        <option value="false">No</option>
                    </select>
                </div>
                <div>
                    <label className="block font-mediun mb-1 text-sm">Mezzo di Contatto</label>
                    <input type="text" name="initial_contact_methods" value={report.initial_contact_methods.join(', ')} onChange={(e) => handleListFieldChange(e, 'initial_contact_methods')} className="border p-2 w-full" />
                </div>
                <div>
                    <label className="block font-mediun mb-1 text-sm">Ammontare</label>
                    <input type="text" name="fraud_amount" value={report.fraud_amount} onChange={handleChange} className="border p-2 w-full" />
                </div>
                <div>
                    <label className="block font-mediun mb-1 text-sm">Nazionalità</label>
                    <select name="promoter_nationality" value={report.promoter_nationality} onChange={handleChange} className="border p-2 w-full">
                        <option value="">Seleziona...</option>
                        <option value="Italiano">Italiano</option>
                        <option value="Non Italiano">Non Italiano</option>
                        <option value="Non Specificato">Non Specificato</option>
                    </select>
                </div>
                <div>
                    <label className="block font-mediun mb-1 text-sm ">Presenza di riferimenti ad interazioni</label>
                    <select name="interactions" value={report.interactions.toString()} onChange={handleChange} className="border p-2 w-full">
                        <option value="true">Sì</option>
                        <option value="false">No</option>
                    </select>
                </div>
                <div>
                    <label className="block font-mediun mb-1 text-sm">Presenza di riferimenti a pubblicità</label>
                    <select name="advertisement" value={report.advertisement.toString()} onChange={handleChange} className="border p-2 w-full">
                        <option value="true">Sì</option>
                        <option value="false">No</option>
                    </select>
                </div>
                <div>
                    <label className="block font-mediun mb-1 text-sm">Presenza di riferimenti a Strumenti Finanziari</label>
                    <select name="is_financial_instruments" value={report.is_financial_instruments.toString()} onChange={handleChange} className="border p-2 w-full">
                        <option value="true">Sì</option>
                        <option value="false">No</option>
                    </select>
                </div>
                <div>
                    <label className="block font-mediun mb-1 text-sm">Presenza di riferimenti a Crypto Asset</label>
                    <select name="is_crypto_assets" value={report.is_crypto_assets.toString()} onChange={handleChange} className="border p-2 w-full">
                        <option value="true">Sì</option>
                        <option value="false">No</option>
                    </select>
                </div>
                <div>
                    <label className="block font-medium mb-1 text-sm">Presenza di riferimenti ad Attività di Interesse </label>
                    <select name="is_micar_competence_activities" value={report.consob_interest.toString()} onChange={handleChange} className="border p-2 w-full">
                        <option value="true">Sì</option>
                        <option value="false">No</option>
                    </select>
                </div>
                <div>
                    <label className="block font-mediun">Elenco siti oscurati presenti nell'esposto</label>
                    <input type="text" name="websites_obscured" value={report.websites_obscured.join(', ')} onChange={(e) => handleListFieldChange(e, 'websites_obscured')} className="border p-2 w-full" />
                </div>
            </div>
            <div className={`mb-4 my-8 flex text-center`}>
                <h2 className='mr-2 font-medium'>Esito Analisi:</h2> <p className={`${resultTextColor} font-bold animate-pulse`}>{bannerMessage}</p>
            </div>
            {report && testMode && (
                <div className='rounded border p-4'>
                    <h3 className='font-medium'>Valutazione dell'esito del'analisi</h3>
                    <div className='grid grid-cols-1 gap-4 py-4'>

                        <div>
                            <label className="block font-mediun mb-1 text-sm">Esito</label>
                            <select name="assessment_passed" value={report.assessment_passed.toString()} onChange={handleChange} className="border p-2 w-full">
                                <option value="true">Superato</option>
                                <option value="false">Non superato</option>
                            </select>
                        </div>
                        <div>
                            <label className="block font-mediun mb-1 text-sm">Note Operatore</label>
                            <textarea name="operator_notes" value={report.operator_notes} onChange={handleChange} className="border p-2 w-full" />
                        </div>
                    </div>

                    <DownloadFraudReportEvaluationButton fraudReport={report} />


                </div>
            )}

        </div>
    );
};

export default FraudReportWidget;
