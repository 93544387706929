import React from 'react';
import { IOSCOAlert } from '../models/IOSCOAlert';

interface IOSCOAlertTableProps {
    alerts: IOSCOAlert[];
}

const IOSCOAlertTable: React.FC<IOSCOAlertTableProps> = ({ alerts }) => {
    return (
        <>
            <table className="min-w-full bg-white border border-gray-300">
                <thead>
                    <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
                        <th className="py-3 px-6 text-left">Company</th>
                        <th className="py-3 px-6 text-left">Regulator</th>
                        <th className="py-3 px-6 text-left">Jurisdiction</th>
                        <th className="py-3 px-6 text-left">Date</th>
                    </tr>
                </thead>
                <tbody className="text-gray-600 text-sm font-light">
                    {alerts.map((alert, index) => (
                        <tr key={index} className="border-b border-gray-200 hover:bg-gray-100">
                            <td className="py-3 px-6 text-left whitespace-nowrap">{alert.Company}</td>
                            <td className="py-3 px-6 text-left">{alert.Regulator}</td>
                            <td className="py-3 px-6 text-left">{alert.Jurisdiction}</td>
                            <td className="py-3 px-6 text-left">{alert.Date}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    );
};

export default IOSCOAlertTable;
