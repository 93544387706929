import React from 'react';
import { WebsiteReport } from '../models/WebsiteReport';
import DownloadScreenshotsButton from '../components/DownloadScreenshotsButton';
import CreatableSelect from 'react-select/creatable';
import DownloadWebsiteReportEvaluationButton from './downloadWebsiteAnalysisEvaluationButton';

interface WebsiteReportWidgetProps {
    report: WebsiteReport;
    setReport: (report: WebsiteReport | null) => void;
    testMode?: boolean;
}


const WebsiteReportWidget: React.FC<WebsiteReportWidgetProps> = ({ report, setReport, testMode = false }) => {

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        const newValue = value === 'true' ? true : value === 'false' ? false : value;

        setReport({
            ...report,
            [name]: newValue,
        });
    };

    const handleListFieldChange = (e: React.ChangeEvent<HTMLInputElement>, fieldName: keyof WebsiteReport) => {
        const { value } = e.target;
        const newValue = value.split(',').map(item => item.trim());

        setReport({
            ...report,
            [fieldName]: newValue,
        });
    };

    const handleLicenseChange = (index: number, e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        const newLicenses = [...report.regulatory_registrations];
        newLicenses[index] = { ...newLicenses[index], [name]: value };

        setReport({
            ...report,
            regulatory_registrations: newLicenses,
        });
    };

    const addLicense = () => {
        setReport({
            ...report,
            regulatory_registrations: [...report.regulatory_registrations, { regulatory_body: '', registration_number: '', company_name: '', country: '' }],
        });
    };

    const removeLicense = (index: number) => {
        setReport({
            ...report,
            regulatory_registrations: report.regulatory_registrations.filter((_, i) => i !== index),
        });
    };

    return (
        <>
            {!report.is_forbidden && <div className="container mx-auto border rounded-lg p-6 shadow-md my-6">
                <h1 className="text-lg font-medium">Esito analisi: {report.base_url}</h1>
                <div className="border-t border-gray-300 mt-1 mb-6"></div>
                <div className="grid grid-cols-1 gap-4">
                    <div>
                        <label className="block font-mediun mb-1 text-sm">Nome Piattaforma</label>
                        <input type="text" name="service_name" value={report.service_name} onChange={handleChange} className="border p-2 w-full" />
                    </div>
                    <div>
                        <label className="block font-mediun mb-1 text-sm">Sito Bloccato</label>
                        <select name="is_forbidden" value={report.is_forbidden.toString()} onChange={handleChange} className="border p-2 w-full">
                            <option value="true">Sì</option>
                            <option value="false">No</option>
                        </select>
                    </div>
                    <div>
                        <label className="block font-mediun mb-1 text-sm">Sito in Italiano</label>
                        <select name="is_italian" value={report.is_italian.toString()} onChange={handleChange} className="border p-2 w-full">
                            <option value="true">Sì</option>
                            <option value="false">No</option>
                        </select>
                    </div>
                    <div>
                        <label className="block font-mediun mb-1 text-sm">Riferimenti ad autorizzazioni</label>
                        <select name="is_authorized" value={report.is_authorized.toString()} onChange={handleChange} className="border p-2 w-full">
                            <option value="true">Sì</option>
                            <option value="false">No</option>
                        </select>
                    </div>
                    <div>
                        <label className="block font-mediun mb-1 text-sm">Presenza di una pagina per l'apertura di un account</label>
                        <select name="has_account_opening_section" value={report.has_account_opening_section.toString()} onChange={handleChange} className="border p-2 w-full">
                            <option value="true">Sì</option>
                            <option value="false">No</option>
                        </select>
                    </div>
                    <div>
                        <label className="block font-mediun mb-1 text-sm">Presenza di una pagina per la registrazione degli utenti</label>
                        <select name="allows_user_registration" value={report.allows_user_registration.toString()} onChange={handleChange} className="border p-2 w-full">
                            <option value="true">Sì</option>
                            <option value="false">No</option>
                        </select>
                    </div>
                    {/* Add more fields similarly */}
                    <div>
                        <label className="block font-mediun mb-1 text-sm">Email</label>
                        <input type="text" name="emails" value={report.emails.join(', ')} onChange={(e) => handleListFieldChange(e, 'emails')} className="border p-2 w-full" />
                    </div>
                    <div>
                        <label className="block font-mediun mb-1 text-sm">Numeri di Telefono</label>
                        <input type="text" name="phone_numbers" value={report.phone_numbers.join(', ')} onChange={(e) => handleListFieldChange(e, 'phone_numbers')} className="border p-2 w-full" />
                    </div>
                    {/* <div>
                        <label className="block font-mediun mb-1 text-sm">Sedi</label>
                        <input type="text" name="locations" value={report.locations.join(', ')} onChange={(e) => handleListFieldChange(e, 'locations')} className="border p-2 w-full" />
                    </div> */}
                    <div>
                        <label className="block font-mediun mb-1 text-sm">Sedi Legali</label>
                        <input type="text" name="legal_addresses" value={report.legal_addresses.join(', ')} onChange={(e) => handleListFieldChange(e, 'legal_addresses')} className="border p-2 w-full" />
                    </div>
                    <div>
                        <label className="block font-mediun mb-1 text-sm">Controparte Contrattuale </label>
                        <input type="text" name="contractual_counterpart_tc" value={report.contractual_counterpart.join(', ')} onChange={(e) => handleListFieldChange(e, 'contractual_counterpart_tc')} className="border p-2 w-full" />
                    </div>
                    <div>
                        <label className="block font-mediun mb-1 text-sm">Pagine di Registrazione e Accesso</label>
                        <input type="text" name="login_registration_urls" value={report.login_registration_urls.join(', ')} onChange={(e) => handleListFieldChange(e, 'login_registration_urls')} className="border p-2 w-full" />
                    </div>
                    <div>
                        <h1 className="text-lg font-medium">Attività MIFID</h1>
                        <div className="border-t border-gray-300 mt-1"></div>
                    </div>
                    <div>
                        <label className="block font-mediun mb-1 text-sm">Percentuale MIFID (%)</label>
                        <input type="number" name="mifid_percentage" value={report.mifid_percentage} onChange={handleChange} className="border p-2 w-full" />
                    </div>
                    <div>
                        <label className="block font-mediun mb-1 text-sm">Attività di competenza MIFID</label>
                        <input type="text" name="mifid_activities" value={report.mifid_activities.join(', ')} onChange={(e) => handleListFieldChange(e, 'micar_activities')} className="border p-2 w-full" />
                    </div>
                    <div>
                        <label className="block font-mediun mb-1 text-sm">Strumenti Finanziari</label>
                        <input type="text" name="financial_instruments" value={report.financial_instruments.join(', ')} onChange={(e) => handleListFieldChange(e, 'crypto_assets')} className="border p-2 w-full" />
                    </div>
                    <div>
                        <h1 className="text-lg font-medium">Attività MICAR</h1>
                        <div className="border-t border-gray-300 mt-1"></div>
                    </div>

                    <div>
                        <label className="block font-mediun mb-1 text-sm">Percentuale MICAR (%)</label>
                        <input type="number" name="micar_percentage" value={report.micar_percentage} onChange={handleChange} className="border p-2 w-full" />
                    </div>
                    <div>
                        <label className="block font-mediun mb-1 text-sm">Attività di competenza MICAR</label>
                        <input type="text" name="micar_activities" value={report.micar_activities.join(', ')} onChange={(e) => handleListFieldChange(e, 'micar_activities')} className="border p-2 w-full" />
                    </div>
                    <div>
                        <label className="block font-mediun mb-1 text-sm">Crypto Assets</label>
                        <input type="text" name="crypto_assets" value={report.crypto_assets.join(', ')} onChange={(e) => handleListFieldChange(e, 'crypto_assets')} className="border p-2 w-full" />
                    </div>
                    <div>
                        <label className="block font-mediun mb-1 text-sm">NFT</label>
                        <select name="nfts" value={report.nfts.toString()} onChange={handleChange} className="border p-2 w-full">
                            <option value="true">Sì</option>
                            <option value="false">No</option>
                        </select>
                    </div>
                    <div>
                        <label className="block font-mediun mb-1 text-sm">E-Money Token</label>
                        <select name="emt_services" value={report.emt_services.toString()} onChange={handleChange} className="border p-2 w-full">
                            <option value="true">Sì</option>
                            <option value="false">No</option>
                        </select>
                    </div>
                    <div>
                        <label className="block font-mediun mb-1 text-sm">Utility Token</label>
                        <select name="utility_tokens" value={report.utility_tokens.toString()} onChange={handleChange} className="border p-2 w-full">
                            <option value="true">Sì</option>
                            <option value="false">No</option>
                        </select>
                    </div>
                    <div>
                        <label className="block font-mediun mb-1 text-sm">Security Token</label>
                        <select name="security_tokens" value={report.security_tokens.toString()} onChange={handleChange} className="border p-2 w-full">
                            <option value="true">Sì</option>
                            <option value="false">No</option>
                        </select>
                    </div>
                    <div>
                        <label className="block font-mediun mb-1 text-sm">ART</label>
                        <select name="art" value={report.art.toString()} onChange={handleChange} className="border p-2 w-full">
                            <option value="true">Sì</option>
                            <option value="false">No</option>
                        </select>
                    </div>
                    <div>
                        <h1 className="text-lg font-medium">Autorizzazioni</h1>
                        <div className="border-t border-gray-300 mt-1"></div>
                    </div>
                    <div>
                        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mb-4 ">


                            {report.regulatory_registrations.map((license, index) => (
                                <div key={index} className="border p-4 rounded-md shadow-md">
                                    <div className="mb-2">
                                        <label className="block font-mediun mb-1 text-sm">Autorità</label>
                                        <input type="text" name="regulatory_body" value={license.regulatory_body} onChange={(e) => handleLicenseChange(index, e)} className="border p-2 w-full" />
                                    </div>
                                    <div className="mb-2">
                                        <label className="block font-mediun mb-1 text-sm">N. Autorizzazione</label>
                                        <input type="text" name="registration_number" value={license.registration_number} onChange={(e) => handleLicenseChange(index, e)} className="border p-2 w-full" />
                                    </div>
                                    <div className="mb-2">
                                        <label className="block font-mediun mb-1 text-sm">Nome Società</label>
                                        <input type="text" name="company_name" value={license.company_name} onChange={(e) => handleLicenseChange(index, e)} className="border p-2 w-full" />
                                    </div>
                                    <div className="mb-2">
                                        <label className="block font-mediun mb-1 text-sm">Stato</label>
                                        <input type="text" name="country" value={license.country} onChange={(e) => handleLicenseChange(index, e)} className="border p-2 w-full" />
                                    </div>
                                    <button onClick={() => removeLicense(index)} className="text-red-500 text-sm">
                                        &times; Rimuovi
                                    </button>
                                </div>
                            ))}

                        </div>

                        <button onClick={addLicense} className="bg-primary-color text-white px-4 py-2 flex items-center justify-center rounded-md">Aggiungi Autorizzazione</button>
                    </div>
                    <div className='py-4'>
                        <div>
                            <h1 className="text-lg font-medium">Screenshot Sito</h1>
                            <div className="border-t border-gray-300 mt-1 mb-2"></div>
                        </div>
                        <div className='py-2'>
                            <label className="block font-normal text-gray-500">Seleziona o aggiungi link per fare screenshot</label>
                            <CreatableSelect
                                isMulti
                                options={report.links_to_screen.map((link) => ({ value: link, label: link }))}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                onChange={(selectedOptions) => {
                                    const selectedLinks = selectedOptions.map((option) => option.value);
                                    setReport({
                                        ...report,
                                        links_to_screen: selectedLinks
                                    });
                                }}
                                value={report.links_to_screen.map((link) => ({ value: link, label: link }))}
                                placeholder="Seleziona o aggiungi link..."
                            />

                            {report.links_to_screen.length > 0 && (
                                <DownloadScreenshotsButton urls={report.links_to_screen} />
                            )}
                        </div>

                    </div>

                </div>
                {report && testMode && (

                    <div className='rounded  p-4 border-2'>
                        <h3 className='font-medium'>Valutazione dell'esito del'analisi</h3>
                        <div className='grid grid-cols-1 gap-4 py-4'>

                            <div>
                                <label className="block font-mediun mb-1 text-sm">Esito</label>
                                <select name="assessment_passed" value={report.assessment_passed.toString()} onChange={handleChange} className="border p-2 w-full">
                                    <option value="true">Superato</option>
                                    <option value="false">Non superato</option>
                                </select>
                            </div>
                            <div>
                                <label className="block font-mediun mb-1 text-sm">Note Operatore</label>
                                <textarea name="operator_notes" value={report.operator_notes} onChange={handleChange} className="border p-2 w-full" />
                            </div>
                        </div>
                        <DownloadWebsiteReportEvaluationButton websiteReport={report} />
                    </div>
                )}
            </div>}
            {report.is_forbidden && <div className='p-4 bg-orange-300 rounded'>
                <h2 className='text-md'> Il sito utilizza sistemi protezione avanzati. Impossibile procedere con l'analisi. </h2>
            </div>}
        </>
    );
};

export default WebsiteReportWidget;
