import React, { ComponentType } from 'react';
import keycloak from '../keycloak';
import { Navigate } from 'react-router-dom';

interface PrivateRouteProps {
    component: ComponentType;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ component: Component }) => {

    return keycloak.authenticated ? <Component /> : <Navigate to="/" />;
};

export default PrivateRoute;
