import React from 'react';
import { Accusation } from '../models/Accusation';

interface GoolgeAlertTableProps {
    alerts: Accusation[];
}

const GoolgeAlertTable: React.FC<GoolgeAlertTableProps> = ({ alerts }) => {
    return (
        <>
            <table className="min-w-full bg-white border border-gray-300">
                <thead>
                    <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
                        <th className="py-3 px-6 text-left">Descrizione</th>
                        <th className="py-3 px-6 text-left">Autorità Coinvolte</th>
                        <th className="py-3 px-6 text-left">Data</th>
                        <th className="py-3 px-6 text-left">Link</th>
                    </tr>
                </thead>
                <tbody className="text-gray-600 text-sm font-light">
                    {alerts.map((alert, index) => (
                        <tr key={index} className="border-b border-gray-200 hover:bg-gray-100">

                            <td className="py-3 px-6 text-left break-words max-w-xs">
                                {alert.description}
                            </td>
                            <td className="py-3 px-6 text-left">
                                {alert.authorithies.map((authority, idx) => (
                                    <div key={idx}>{authority}</div>
                                ))}
                            </td>
                            <td className="py-3 px-6 text-left break-words max-w-xs">
                                {alert.date}
                            </td>
                            <td className="py-3 px-6 text-left max-w-xs">
                                <a href={alert.link} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">
                                    Vai alla risorsa
                                </a>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    );
};

export default GoolgeAlertTable;