// src/components/WebsiteAnalysis.tsx
import React, { useState } from 'react';
import axios from 'axios';
import { fetchConsobRegistrations } from '../api/searchApi';
import { ConosbRegistration } from '../models/ConsobRegistration';
import ConsobListTable from '../components/ConsobListTable';




const ConsobListCompanySearch: React.FC = () => {
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [registrations, setRegistrations] = useState<ConosbRegistration[] | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const handleSearch = async (event: React.FormEvent) => {
        event.preventDefault();
        setLoading(true);
        setError(null);

        try {
            const results = await fetchConsobRegistrations(searchTerm);
            setRegistrations(results);
            console.log(registrations);
        } catch (error: any) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="container mx-auto p-4">
            <h1 className="text-xl font-bold mb-4 text-primary-color">Ricerca in Elenchi CONSOB</h1>
            <div className='max-w-4xl my-4'>
                <p>

                    Cliccando su "Cerca", verrà effettuata una ricerca sugli elenchi forniti dalla CONSOB.<br />
                    Le aziende con un nome simile a quello inserito verranno estratte, utilizzando i dati disponibili e aggiornati mensilmente.</p>
            </div>
            <div className='max-w-4xl'>
                <form onSubmit={handleSearch} className="mb-4 grid grid-cols-2 sm:grid-cols-auto-fit gap-4 items-center ">
                    <input
                        type="text"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className="border p-2 flex-grow"
                        placeholder="Inserisci il nome dell'azienda..."
                    />
                    <div>
                        <button
                            type="submit"
                            className={`bg-primary-color text-white px-4 py-2 rounded flex items-center justify-center ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
                            disabled={loading}
                        >
                            {loading ? (
                                <>
                                    <span>Ricerca in corso</span>
                                    <svg className="animate-spin h-5 w-5 ml-3 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
                                    </svg>
                                </>
                            ) : 'Cerca'}
                        </button>
                    </div>
                </form>
            </div>

            {error && <p className="text-red-500">{error}</p>}
            {registrations && <ConsobListTable registrations={registrations} />}
        </div>
    );
};

export default ConsobListCompanySearch;
