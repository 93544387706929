import axios from '../api/axios';

const downloadScreenshots = async (urls: string[]): Promise<string> => {
    try {
        const response = await axios.post(
            '/screenshot/zip',
            { urls },
            { responseType: 'blob' } // Important to handle binary response
        );
        // Create a link to download the file
        return window.URL.createObjectURL(new Blob([response.data]));

        // Create a link to download the file

    } catch (error) {
        throw new Error('Error downloading the screenshots');
    }
};

export { downloadScreenshots };
